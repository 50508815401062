import React from 'react'
import { graphql } from 'gatsby'
import { Heading, Section, Wysiwyg, Container } from 'src/components/Base'
import {
  Hero,
  Branches,
  AboutUs,
  SalesArguments,
  ContactPerson,
} from 'src/sections'
import Seo from 'src/components/Seo'

const TemplateBranchesOverview = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const intro = page.atspBranchesOverview.branchesOverviewIntro
  const { branches } = page.atspBranches
  const aboutUs = page.atspBranchesOverview.branchesOverviewAboutUs
  const salesArguments =
    page.atspBranchesOverview.branchesOverviewSalesArguments

  const contact = page.atspBranchesOverview.branchesOverviewContact
  const contactData =
    page.atspBranchesOverview.branchesOverviewContact.contactPerson

  const portrait =
    contactData.atspcontacts.portrait.localFile.childImageSharp
      .gatsbyImageData || undefined
  const altText = contactData.atspcontacts.portrait.altText || undefined

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.heading}
        </Heading>
        <Container>
          <Wysiwyg isCentered>{intro.wysiwyg}</Wysiwyg>
        </Container>
      </Section>
      <Branches
        cardText={branches.wysiwyg}
        introText={branches.wysiwygIntro}
        heading={branches.heading}
        hasFirstCard
      />
      <AboutUs
        divider
        heading={aboutUs.heading}
        text={aboutUs.wysiwyg}
        hasBackground
      />
      <SalesArguments
        heading={salesArguments.heading}
        intro={salesArguments.wysiwyg}
        salesArgumentsRepeater={salesArguments.repeater}
      />
      <ContactPerson
        heading={contact.heading}
        name={contactData.title}
        extensionNumber={contactData.atspcontacts.contactData.extension}
        email={contactData.atspcontacts.contactData.email}
        portrait={portrait}
        altText={altText}
        hasBackground
      />
    </>
  )
}

export const pageQuery = graphql`
  query BranchesOverviewById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspBranchesOverview {
        branchesOverviewIntro {
          heading
          wysiwyg
        }
        branchesOverviewAboutUs {
          heading
          wysiwyg
        }
        branchesOverviewSalesArguments {
          heading
          wysiwyg
          repeater {
            heading
            wysiwyg
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
        branchesOverviewContact {
          heading
          contactPerson {
            ... on WpKontakt {
              title
              id
              atspcontacts {
                contactData {
                  email
                  extension
                }
                portrait {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
      atspBranches {
        branches {
          heading
          wysiwygIntro
          wysiwyg
        }
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
    }
  }
`
export default TemplateBranchesOverview
